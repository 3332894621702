var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:_vm.$style.card},[_c('Table',{attrs:{"headers":[_vm.table_expand_col ].concat( _vm.filtered_cols),"items":_vm.statistics,"options":_vm.table_options,"custom-sort":_vm.prevent_sort,"loading":_vm.statistics_loading,"hide-default-footer":"","show-expand":"","no-data-text":"Курсы, удовлетворяющие выбранным параметрам, отсутствуют","not_resizeble":true},on:{"update:options":function($event){_vm.table_options=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},_vm._l((item.courses),function(course,idx){return _c('div',{key:idx,staticClass:"d-flex"},[_c('span',{class:[_vm.$style.item,'py-2 px-4']},[_vm._v(" "+_vm._s(course.course_name)+" ")]),_c('div',{class:[_vm.$style.item_count_wrapper]},[_vm._v(" "+_vm._s(course.quantity_valid)+" ")]),_c('div',{class:[_vm.$style.item_count_wrapper]},[_vm._v(" "+_vm._s(course.quantity_expired)+" ")])])}),0)]}},{key:"item.type_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2 font-weight-bold"},[_vm._v(" "+_vm._s(item.type_name)+" ")])]}},{key:"item.quantity_valid",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2 font-weight-bold"},[_vm._v(" "+_vm._s(item.quantity_valid)+" ")])]}},{key:"item.quantity_expired",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2 font-weight-bold"},[_vm._v(" "+_vm._s(item.quantity_expired)+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }